import { API } from "aws-amplify";

export async function fetchConsumerPlatforms(api = API) {
    try {
        const response = await api.get("api", "integration/platforms", {});
        return response
    } catch (e) {
        throw e;
    }
}

export async function fetchActivePlatforms(api = API) {
    try {
        const response = await api.get("api", "integration/platforms", {});
        const platforms = response && response.length > 0 ? response.filter((platform:any)=> platform.platformStatus === "enable") : []
        return platforms
    } catch (e) {
        throw e;
    }
}

export async function savePlatforms(body:any, operation:string, api = API) {
    try {

        if(operation === "edit") {
           return await updatePlatform(body)
        }

        return await createNewPlatform(body)
    } catch (e) {
        throw e;
    }
}

const createNewPlatform = async(body:any, api = API):Promise<any> => {
    return api.post("api", "integration/platforms", {body: body})
}

const updatePlatform = async(body:any, api = API):Promise<any> => {
    return api.put("api", "integration/platforms", {body: body})
}

export async function deletePlatform (platformId: string, api=API) {
    try {
        return await api.del("api", `integration/platforms/${platformId}`, {body:{platformId}})
    } catch (e) {
        throw e;
    }
    
}
